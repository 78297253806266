import React, { useState, useEffect } from "react";
import configData from "./config.json"; 
import "./GridList.css";


function GridList({ isDarkMode, places }) {
    const gridTheme = isDarkMode ? "dark" : "light";
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(getItemsPerPage());

    const sortedPlaces = [...places].sort((a, b) => a.distance - b.distance);
    const totalItems = sortedPlaces.length;
    const totalPages = Math.ceil(totalItems / itemsPerPage);

    const startIdx = (currentPage - 1) * itemsPerPage;
    const endIdx = startIdx + itemsPerPage;

    const displayedItems = sortedPlaces.slice(startIdx, endIdx);

    const getCategory = (place) => {
        for (let category in configData.PolygonsAndPlaces) {
          for (let typeObj of configData.PolygonsAndPlaces[category]) {
            if (typeObj.Type === place.type) {
              return category;
            }
          }
        }
        return "";
    };
    const getMarkerIcon = (place) => {
        for (let category in configData.PolygonsAndPlaces) {
          for (let typeObj of configData.PolygonsAndPlaces[category]) {
            if (typeObj.Type === place.type) {
              return typeObj.Icon;
            }
          }
        }
        return "";
    };

    function getItemsPerPage() {
        const width = window.innerWidth;
        if (width <= 800) {
            return 6;
        } else if (width <= 1050) {
            return 12;
        } else {
            return 20;
        }
    }

    useEffect(() => {
        function handleResize() {
            setItemsPerPage(getItemsPerPage());
        }
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        <div className={`grid-list-component-container ${gridTheme}`}>
            <div className="grid-list-controls-container">
                <div className="pagination-container">
                    <div className="pagination">
                        <button onClick={() => setCurrentPage(1)} disabled={currentPage === 1}>
                            Start
                        </button>
                        <button onClick={() => setCurrentPage((prev) => prev - 1)} disabled={currentPage === 1}>
                            Previous
                        </button>
                        <span>{currentPage}</span>
                        <button onClick={() => setCurrentPage((prev) => prev + 1)} disabled={currentPage === totalPages}>
                            Next
                        </button>
                        <button onClick={() => setCurrentPage(totalPages)} disabled={currentPage === totalPages}>
                            End
                        </button>
                    </div>
                </div>
                <div className="info-container">
                    {/* Info Button and Dropdown */}
                    <button className="info-button">?</button>
                    <div className="info-dropdown">
                        <p><strong>Information on list colors:</strong></p>
                        <p>🟩 Green is <strong>greenspace</strong></p>
                        <p>🟧 Orange is <strong>sports and recreation</strong></p>
                        <p>🟪 Purple is <strong>landmarks, culture, and entertainment</strong></p>
                    </div>
                </div>
            </div>
            <div className={"places-grid-list-container"}>
                {displayedItems.map((place, index) => {
                    const category = getCategory(place);
                    const iconMarker = getMarkerIcon(place);
                    return (
                        <div key={index} className={`place-item ${category}`}>
                            <h2>{place.name}</h2>
                            {place.gs_parent && <h3>Located in <strong>{place.gs_parent}</strong></h3>}
                            <h3>{place.type} {iconMarker}</h3>
                            <div className="divider"></div>
                            <div className="info-pair">
                                <div className="label-wrapper">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 384 512"
                                        className="address-icon icon"
                                    >
                                        <path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z"/>
                                    </svg>
                                </div>
                                {place.address}
                            </div>
                            <div className="info-pair">
                                <div className="label-wrapper">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 512 512"
                                        className="public-access icon"
                                    >
                                        <path d="M0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zm161.5-86.1c-12.2-5.2-26.3 .4-31.5 12.6s.4 26.3 12.6 31.5l11.9 5.1c17.3 7.4 35.2 12.9 53.6 16.3v50.1c0 4.3-.7 8.6-2.1 12.6l-28.7 86.1c-4.2 12.6 2.6 26.2 15.2 30.4s26.2-2.6 30.4-15.2l24.4-73.2c1.3-3.8 4.8-6.4 8.8-6.4s7.6 2.6 8.8 6.4l24.4 73.2c4.2 12.6 17.8 19.4 30.4 15.2s19.4-17.8 15.2-30.4l-28.7-86.1c-1.4-4.1-2.1-8.3-2.1-12.6V235.5c18.4-3.5 36.3-8.9 53.6-16.3l11.9-5.1c12.2-5.2 17.8-19.3 12.6-31.5s-19.3-17.8-31.5-12.6L338.7 175c-26.1 11.2-54.2 17-82.7 17s-56.5-5.8-82.7-17l-11.9-5.1zM256 160a40 40 0 1 0 0-80 40 40 0 1 0 0 80z"/>
                                    </svg>
                                    <strong>Public access</strong>
                                </div>
                                {place.publicAccess ? "Yes" : "No"}
                            </div>
                            <div className="info-pair">
                                <div className="label-wrapper">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 640 512"
                                        className="ruler-distance icon"
                                    >
                                    <path d="M0 336c0 26.5 21.5 48 48 48l544 0c26.5 0 48-21.5 48-48l0-160c0-26.5-21.5-48-48-48l-64 0 0 80c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-80-64 0 0 80c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-80-64 0 0 80c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-80-64 0 0 80c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-80-64 0 0 80c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-80-64 0c-26.5 0-48 21.5-48 48L0 336z"/>
                                    </svg>
                                    <strong>Distance to center</strong>
                                </div>
                                {place.distance}m
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

export default GridList;
