import React from "react";
import "./Footer.css";

function Footer() {
    return (
        <footer className="app-footer">
            ©2024 Stomping Grounds, Inc. | v0.2.0
        </footer>
    );
}

export default Footer;
