import React, { useState, useEffect, useCallback, useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import shortid from "shortid";
import axios from "axios";
import configData from "./config.json";
import Navbar from "./Navbar";
import SearchFilter from "./SearchFilter";
import MapComponent from "./MapComponent";
import GridList from "./GridList";
import Footer from "./Footer";
import "./App.css";


function App() {
  const [isDarkMode, setIsDarkMode] = useState(() => {
    const hour = new Date().getHours();
    return hour < 6 || hour >= 18; // true if current time is between 6 PM and 6 AM
  });
  const toggleDarkMode = () => {
    setIsDarkMode(prevMode => !prevMode);
  };
  const [captchaVerified, setCaptchaVerified] = useState(false);
  const onCaptchaChange = (value) => {
    setCaptchaVerified(!!value);
  };

  const [error, setError] = useState({ hasError: false, message: "" });
  const [showErrorPopup, setShowErrorPopup] = useState(true);
  const handleCloseError = () => {
    setShowErrorPopup(false);
  };

  const autocompleteInputRef = useRef(null);
  const loadGooglePlacesScript = () => {
    if (window.google) {
      initializeAutocomplete();
      return;
    }
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places`;
    script.onload = () => initializeAutocomplete();
    document.body.appendChild(script);
  };
  const initializeAutocomplete = () => {
    if (!autocompleteInputRef.current || !window.google) return;
    const autocomplete = new window.google.maps.places.Autocomplete(
      autocompleteInputRef.current,
    );
    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();
      const address = place.formatted_address || place.name;
      setLocation(address);
    });
  };

  const [isSearching, setIsSearching] = useState(false);
  const [location, setLocation] = useState("");
  const [searchedLocation, setSearchedLocation] = useState("");
  const handleMapRightClick = async (lat, lng) => {
    const clickLocation = `${lat.toFixed(6)}, ${lng.toFixed(6)}`;
    setLocation(clickLocation);
    setCenter({ lat, lng });
  };

  const [selectedFilters, setSelectedFilters] = useState([]);
  const [selectedDistance, setSelectedDistance] = useState(2000);
  const [isPublicAccessOnly, setIsPublicAccessOnly] = useState(false);
  const categoryMapping = {
    "Greenspaces": "Greenspaces",
    "Sports and Recreation": "SportsAndRec",
    "Landmarks, Culture, Entertainment": "LandmarksAndCulturalAndEntertainment",
  };
  const applyFiltersToPlaces = useCallback((allPlaces) => {
    let filteredPlaces = [...allPlaces];
    if (selectedFilters.length > 0) {
      filteredPlaces = filteredPlaces.filter(place => {
        const placeCategories = Object.keys(configData.PolygonsAndPlaces).filter(category => {
          const mappedCategory = categoryMapping[selectedFilters.find(filter => categoryMapping[filter] === category)];
          return mappedCategory && configData.PolygonsAndPlaces[category].some(item => item.Type === place.type);
        });
        return selectedFilters.includes(place.type) || placeCategories.length > 0;
      });
    }
    if (isPublicAccessOnly) {
      filteredPlaces = filteredPlaces.filter(place => place.publicAccess);
    }
    if (selectedDistance !== 2000) {
      filteredPlaces = filteredPlaces.filter(place => parseFloat(place.distance) <= selectedDistance);
    }
    return filteredPlaces;
  }, [selectedFilters, isPublicAccessOnly, selectedDistance]);

  const [isMapView, setIsMapView] = useState(false);
  const [isMapViewDisabled, setIsMapViewDisabled] = useState(false);
  const handleMapViewClick = () => {
    setIsMapView(prevIsMapView => {
      setIsMapViewDisabled(true);
      setTimeout(() => {
        setIsMapViewDisabled(false);
      }, 700);
      return !prevIsMapView;
    });
  };

  const [mapContainerHeight, setMapContainerHeight] = useState(null); // Default height
  const infoSectionRef = useRef(null);
  const searchBarRef = useRef(null);
  const updateMapHeight = () => {
    const searchBarHeight = searchBarRef.current ? searchBarRef.current.offsetHeight : 0;
    const infoSectionHeight = infoSectionRef.current ? infoSectionRef.current.offsetHeight : 0;
    const totalHeight = searchBarHeight + infoSectionHeight + 9;
    const windowHeight = window.innerHeight;
    const mapHeight = windowHeight - totalHeight;
    setMapContainerHeight(`${mapHeight}px`);
  };

  const [infoData, setInfoData] = useState(null);
  const [center, setCenter] = useState(null);
  const [gsPolygons, setGSPolygons] = useState([]);
  const [places, setPlaces] = useState([]);
  const [filteredPlaces, setFilteredPlaces] = useState([]);

  const searchLocation = async () => {
    try {
      if (location !== searchedLocation) {
        console.log(location)
        setError({ hasError: false, message: "" });
        setShowErrorPopup(true);
        setIsMapView(true);
        setIsSearching(true);
        setGSPolygons([]);
        setPlaces([]);
        const response = await axios.get("https://api.stompinggrounds.fyi/all_data", {
          params: {
            api_key: process.env.REACT_APP_SG_API_KEY,
            location: location
          }
        });
        const data = response.data;
        // Create object for storing info
        setInfoData({
          address: location,
          greenspaceScore: data.Greenspace_score,
          phrase: data.Phrase,
          description: data.Description,
          topGreenspaceContributor: data.Top_greenspace_contributor
        });
        // Create the center object
        setCenter({
          lat: parseFloat(data.Center[0]),
          lng: parseFloat(data.Center[1])
        });
        // Create the objects for the greenspace polygons
        const parsedGSPolygons = Object.values(data.Closest_greenspaces).map(gs => {
          const polygonGS = JSON.parse(gs.Polygon);
          const transformedPolygonGS = polygonGS[0].map(coord => {
            return {
                lat: parseFloat(coord[1]),
                lng: parseFloat(coord[0])
            };
          });
          return transformedPolygonGS;
        });
        setGSPolygons(parsedGSPolygons)
        // Create the objects for place markers from the greenspace polygons and places
        const parsedGSPlaces = Object.values(data.Closest_greenspaces).map(gsp => {
          return {
            id: shortid.generate(),
            name: gsp.Name,
            address: gsp.Address,
            position: {
                lat: parseFloat(gsp.Center[0]),
                lng: parseFloat(gsp.Center[1])
            },
            type: gsp.Type,
            gs_polygon: true,
            gs_parent: "",
            publicAccess: gsp.Public_access === "true",
            distance: parseFloat(gsp.Distance_to_greenspace_point_m).toFixed(2),
          };
        });
        const parsedPlaces = Object.values(data.Closest_places).map(pl => {
          return {
            id: shortid.generate(),
            name: pl.Name,
            address: pl.Address,
            position: {
              lat: parseFloat(pl.Center[0]),
              lng: parseFloat(pl.Center[1])
            },
            type: pl.Type,
            gs_polygon: false,
            gs_parent: pl.Greenspace_polygon,
            publicAccess: pl.Public_access === "true",
            distance: parseFloat(pl.Distance_to_greenspace_point_m).toFixed(2),
          };
        });
        setPlaces([...parsedPlaces, ...parsedGSPlaces]);
        setSearchedLocation(location);
        setIsSearching(false);
      }
    } catch (e) {
      setIsSearching(false);
      console.error(e);
      if (e.response && e.response.data && e.response.data.error) {
        const errorMessage = e.response.data.error;

        if (errorMessage.includes("No data available for location") ||
            errorMessage.includes("Unable to determine the geo_id for the target point") ||
            errorMessage.includes("Cannot find address for provided point")) {
          setError({ hasError: true, message: "No data available for the provided address or coordinate 😔" });

        } else if (errorMessage.includes("Cannot find point for provided address")) {
          setError({ hasError: true, message: "Address could not be found 🤔" });

        } else {
          setError({ hasError: true, message: "An unexpected error occurred. Please try again later 😕" });
        }
      } else if (e.request) {
        setError({ hasError: true, message: "The request was made, but no response was received. Check your network and try again." });
      } else {
        setError({ hasError: true, message: "There was a problem with the request. Please try again." });
      }
    }
  };
  const searchLocationWithVerification = async () => {
    if (!captchaVerified) {
      alert("Please verify you are not a robot.");
      return;
    }
    searchLocation();
  };

  useEffect(() => {
    const styleTag = document.createElement("style");
    styleTag.id = "google-places-autocomplete-styles";
    document.head.appendChild(styleTag);
    return () => {
      const existingStyleTag = document.getElementById("google-places-autocomplete-styles");
      if (existingStyleTag) {
        document.head.removeChild(existingStyleTag);
      }
    };
  }, [isDarkMode]);

  useEffect(() => {
    const handleResize = () => {
      if (isSearching || infoData) {
        updateMapHeight();
      }
    };
    window.addEventListener("resize", handleResize);
    // Calculate the height initially or when isSearching becomes true or when infoData is populated
    if (isSearching || infoData) {
      updateMapHeight();
    }
    // Clean up the event listener when the component unmounts or the dependencies change
    return () => window.removeEventListener("resize", handleResize);
  }, [isSearching, infoData]);

  useEffect(() => {
    const allPlaces = [...places];
    const updatedFilteredPlaces = applyFiltersToPlaces(allPlaces);
    setFilteredPlaces(updatedFilteredPlaces);
  }, [selectedFilters, selectedDistance, isPublicAccessOnly, places, applyFiltersToPlaces]);

  useEffect(() => {
    loadGooglePlacesScript();
    // searchLocation();
  }, []);

  return (
    <div className={`App ${isDarkMode ? "dark" : "light"}`}>
      {error.hasError && showErrorPopup && (
        <div className="error-popup">
          {error.message}
          <button onClick={handleCloseError} className="error-close-button">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 384 512"
            >
              <path d="M376.6 84.5c11.3-13.6 9.5-33.8-4.1-45.1s-33.8-9.5-45.1 4.1L192 206 56.6 43.5C45.3 29.9 25.1 28.1 11.5 39.4S-3.9 70.9 7.4 84.5L150.3 256 7.4 427.5c-11.3 13.6-9.5 33.8 4.1 45.1s33.8 9.5 45.1-4.1L192 306 327.4 468.5c11.3 13.6 31.5 15.4 45.1 4.1s15.4-31.5 4.1-45.1L233.7 256 376.6 84.5z"/>
            </svg>
          </button>
        </div>
      )}
      <Navbar isDarkMode={isDarkMode} toggleDarkMode={toggleDarkMode}/>
      {!isSearching && !searchedLocation && (
        <div 
          className={`background-image ${isDarkMode ? "background-night" : "background-day"}`} 
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL + (isDarkMode ? "/night.webp" : "/day.webp")})`
          }}
        ></div>
      )}
      {(isSearching || searchedLocation) ? (
        <div className="search-filter-bar-main-container" ref={searchBarRef}>
          <div className="input-container">
            <input
              ref={autocompleteInputRef}
              value={location}
              onChange={e => setLocation(e.target.value)}
              onKeyDown={e => {
                if (e.key === "Enter" && location.trim()) {
                  searchLocationWithVerification();
                }
              }}
              placeholder="Enter address or coordinates"
              className="input"
            />
            <button
              onClick={searchLocationWithVerification}
              disabled={!location.trim()}
              className="search-button"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                className="search-icon"
              >
                <path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"/>
              </svg>
            </button>
          </div>
          <SearchFilter
            isDarkMode={isDarkMode}
            selectedFilters={selectedFilters}
            onSelectFilter={(filter) => {
              if (selectedFilters.includes(filter)) {
                setSelectedFilters(selectedFilters.filter(f => f !== filter));
              } else {
                setSelectedFilters([...selectedFilters, filter]);
              }
            }}
            onClearFilters={(callback) => {
              setSelectedFilters([]);
              if (callback) callback();
            }}
            selectedDistance={selectedDistance}
            onSelectDistance={setSelectedDistance}
            isPublicAccessOnly={isPublicAccessOnly}
            togglePublicAccess={() => setIsPublicAccessOnly(prev => !prev)}
          />
        </div>
      ) : (
        <div className="search-bar-launch-container">
          <div className="input-container">
            <input
              ref={autocompleteInputRef}
              value={location}
              onChange={e => setLocation(e.target.value)}
              onKeyDown={e => {
                if (e.key === "Enter" && location.trim()) {
                  searchLocationWithVerification();
                }
              }}
              placeholder="Enter address or coordinates"
              className="input"
            />
            <button
              onClick={searchLocationWithVerification}
              disabled={!location.trim()}
              className="search-button"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                className="search-icon"
              >
                <path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"/>
              </svg>
            </button>
          </div>
          <div className="captcha-container" style={{transform:"scale(0.75)"}}>
            <ReCAPTCHA
              key={isDarkMode ? "dark" : "light"}
              sitekey={process.env.REACT_APP_GOOGLE_CAPTCHA_KEY}
              onChange={onCaptchaChange}
              theme={isDarkMode ? "dark" : "light"}
            />
          </div>
        </div>
      )}
      {infoData &&
        <div className="info-section" ref={infoSectionRef}>
          <h2>{infoData.address}</h2>
          <p className="places-shown">{filteredPlaces.length} places shown</p>
          <p><b>Greenspace Score®: {infoData.greenspaceScore}</b></p>
          <p><b>{infoData.phrase}.</b> {infoData.description} <b>{infoData.topGreenspaceContributor}</b> is the top contributor to greenspace.</p>
        </div>
      }
      {isMapView && (isSearching || searchedLocation) &&
        <div className="map-container" style={{ height: mapContainerHeight }}>
          <MapComponent
            isDarkMode={isDarkMode}
            isSearching={isSearching}
            center={center}
            polygons={gsPolygons}
            places={filteredPlaces}
            selectedDistance={selectedDistance}
            onMapRightClick={handleMapRightClick}
            error={error}
          />
          {!isSearching &&
            <button
              onClick={handleMapViewClick}
              disabled={isMapViewDisabled}
              className="toggle-map-view-button" 
            >
              Show List
            </button>
          }
        </div>
      }
      {places && places.length > 0 && !isMapView &&
        <div className="places-list">
          <GridList
            isDarkMode={isDarkMode}
            places={filteredPlaces}
          />
          {!isSearching &&
            <button
              onClick={handleMapViewClick}
              disabled={isMapViewDisabled}
              className="toggle-map-view-button" 
            >
              Show Map
            </button>
          }
        </div>
      }
      {!isMapView &&
        <div className="footer-container">
          <Footer/>
        </div>
      }
    </div>
  );
}

export default App;
