import React, { useEffect, useRef } from "react";
import configData from "./config.json";
import "./SearchFilter.css";


function SearchFilter({
    isDarkMode,
    selectedFilters,
    onSelectFilter,
    onClearFilters,
    selectedDistance,
    onSelectDistance,
    isPublicAccessOnly,
    togglePublicAccess
}) {
    const searchBarTheme = isDarkMode ? "dark" : "light";
    const inputRef = useRef(null);
    const dropdownRef = useRef(null);

    const [filterSearch, setFilterSearch] = React.useState("");
    const [showDropdown, setShowDropdown] = React.useState(false);

    const extractAvailableTypes = () => {
        const topLevelTypes = Object.keys(configData.PolygonsAndPlaces);
        const individualTypes = topLevelTypes.flatMap(level =>
            configData.PolygonsAndPlaces[level].map(item => ({type: item.Type, icon: item.Icon}))
        );
        return [...individualTypes];
    };
    const availableTypes = extractAvailableTypes();
    const filteredTypes = filterSearch === ""
        ? availableTypes
        : availableTypes.filter(item => item.type.toLowerCase().includes(filterSearch.toLowerCase()));

    const handleClearAll = () => {
        onClearFilters(() => {
            setFilterSearch("");
            setShowDropdown(false);
        });
    };
    const sortedFilteredTypes = filteredTypes.sort((a, b) => {
        const aSelected = selectedFilters.includes(a.type);
        const bSelected = selectedFilters.includes(b.type);
        if (aSelected && !bSelected) {
            return -1;
        }
        if (!aSelected && bSelected) {
            return 1;
        }
        return a.type.localeCompare(b.type);
    });

    useEffect(() => {
        function handleClickOutside(event) {
            if (
                inputRef.current && !inputRef.current.contains(event.target) &&
                dropdownRef.current && !dropdownRef.current.contains(event.target)
            ) {
                setShowDropdown(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div className={`filter-section ${searchBarTheme}`}>
            {/* Type Filter Search */}
            <div className="filter-search-bar filter-item">
                <input
                    ref={inputRef}
                    value={filterSearch} 
                    onChange={e => {
                        setFilterSearch(e.target.value);
                        setShowDropdown(e.target.value.trim() !== "");
                    }}
                    onFocus={e => {
                        setShowDropdown(true);
                    }}
                    placeholder="Filter by..." 
                />
                {showDropdown && (
                    <div ref={dropdownRef} className="filter-search-dropdown">
                        {sortedFilteredTypes.map(({type, icon}) => (
                            <div
                                key={type}
                                onClick={() => onSelectFilter(type)}
                                className={`dropdown-item ${selectedFilters.includes(type) ? "selected" : ""}`}
                            >
                                {icon} {type}
                            </div>
                        ))}
                    </div>
                )}
                <button className="filter-search-clear-button" onClick={handleClearAll}>Clear all</button>
            </div>
            {/* Distance Filter */}
            <div className="distance-filter filter-item">
                <label className="distance-label">Distance</label>
                <select className="distance-select" value={selectedDistance} onChange={e => onSelectDistance(e.target.value)}>
                    <option value={300}>300 m</option>
                    <option value={600}>600 m</option>
                    <option value={900}>900 m</option>
                    <option value={1200}>1200 m</option>
                    <option value={1500}>1500 m</option>
                    <option value={1800}>1800 m</option>
                    <option value={2000}>2000 m</option>
                </select>    
            </div>
            {/* Public Access Filter */}
            <div className="public-access-filter filter-item">
                <label>
                    Public only
                    <input 
                        type="checkbox" 
                        checked={isPublicAccessOnly}
                        onChange={togglePublicAccess}
                    />
                </label>
            </div>
        </div>
    );
}

export default SearchFilter;
