import React, { useState, useRef } from "react";
import { GoogleMap, Polygon, Circle, Marker, InfoWindow } from "@react-google-maps/api";
import { Loader, getIconUtility, getMapStyleUtility } from "./MapUtils";
import "./MapComponent.css";


const MapComponent = ({
  isDarkMode,
  isSearching,
  center,
  polygons,
  places,
  selectedDistance,
  onMapRightClick,
  error
}) => {
  const mapStyle = getMapStyleUtility(isDarkMode); 
  const mapContainerSize = {
    height: "100%",
    width: "100%"
  };
  const getIconForPlace = (type) => {
    return getIconUtility(type);
  }

  const [selectedPlace, setSelectedPlace] = React.useState(null);
  const [isMouseOverInfoWindow, setIsMouseOverInfoWindow] = useState(false);
  const closeTimeoutRef = useRef(null);

  return (
    <div style={{position: "relative", height: "100%", width: "100%"}}>
      <style>
        {`.gm-ui-hover-effect {display: none !important;}`}
      </style>
      {isSearching && !error.hasError && <Loader />}
      <GoogleMap
        className="google-map"
        mapContainerStyle={mapContainerSize}
        zoom={14}
        options={{
          styles: mapStyle.mapColorScheme,
          zoomControl: !isSearching,
          gestureHandling: isSearching ? "none" : "auto",
          disableDoubleClickZoom: isSearching,
          clickableIcons: false
        }}
        center={center}
        defaultCenter={{ lat: 37.77, lng: -122.51 }}
        onRightClick={(e) => onMapRightClick(e.latLng.lat(), e.latLng.lng())}
      >
        {/* Center marker */}
        <Marker
            position={center}
            icon={{
              url: "data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='50' height='50'><circle cx='25' cy='25' r='24' fill='%23007BFF33' /><circle cx='25' cy='25' r='16' fill='%23007BFF' /></svg>",
              scaledSize: new window.google.maps.Size(25, 25)
            }}
        />
        {/* 2km Circle around the center marker */}
        <Circle 
          key={selectedDistance}
          center={center}
          radius={Number(selectedDistance)} // 2km in meters (unfiltered view)
          options={{
            strokeColor: mapStyle.circleStyle,
            strokeOpacity: 0.2,
            strokeWeight: 2,
            fillColor: mapStyle.circleStyle,
            fillOpacity: 0.07,
            clickable: false
          }}
        />
        {/* Place markers */}
        {places.map((place) => (
          <Marker
            key={place.id}
            position={place.position}
            title={place.name}
            icon={getIconForPlace(place.type)}
            onMouseOver={() => {
              if (closeTimeoutRef.current) {
                  clearTimeout(closeTimeoutRef.current);
              }
              setSelectedPlace(place);
            }}
            onMouseOut={() => {
                closeTimeoutRef.current = setTimeout(() => {
                  if (!isMouseOverInfoWindow) {
                    setSelectedPlace(null);
                  }
                }, 300); // 300ms delay to move to the InfoWindow
            }}
          >
            {/* Selected place marker */}
            {selectedPlace && place.name === selectedPlace.name && (
              <InfoWindow
                className="info-window"
                position={selectedPlace.position}
                options={{ disableAutoPan: true }}
              >
                <div
                  className="place-popup"
                  onPointerEnter={() => {
                    setIsMouseOverInfoWindow(true);
                    if (closeTimeoutRef.current) {
                        clearTimeout(closeTimeoutRef.current);
                    }
                  }}
                  onPointerLeave={() => {
                    setIsMouseOverInfoWindow(false);
                    setSelectedPlace(null);
                  }}
                  style={{
                    width: "100%",
                    height: "100%",
                    padding: "0",
                    margin: "0",
                    color: "#000000"
                  }}
                >
                  <h2>{selectedPlace.name}</h2>
                  {selectedPlace.gs_parent && <h3>Located in <strong>{selectedPlace.gs_parent}</strong></h3>}
                  <h3>{selectedPlace.type}</h3>
                  <div className="divider"></div>
                  <div className="info-pair">
                      <div className="label-icon-wrapper">
                          <img
                              src="/location-dot-solid.svg"
                              alt="Address Icon"
                              className="icon"
                          />
                      </div>
                      {selectedPlace.address}
                  </div>
                  <div className="info-pair">
                      <div className="label-icon-wrapper">
                          <img
                              src="/universal-access-solid.svg"
                              alt="Access Icon"
                              className="icon"
                          />
                          <strong>Public access</strong>
                      </div>
                      {selectedPlace.publicAccess ? "Yes" : "No"}
                  </div>
                  <div className="info-pair">
                      <div className="label-icon-wrapper">
                          <img
                              src="/ruler-horizontal-solid.svg"
                              alt="Distance Icon"
                              className="icon"
                          />
                          <strong>Distance to center</strong>
                      </div>
                      {selectedPlace.distance}m
                  </div>
                </div>
              </InfoWindow>
            )}
          </Marker>
        ))}
        {/* Polygons */}
        {polygons.map((polygon, index) => (
          <Polygon
            key={index}
            path={polygon}
            options={{
              strokeColor: mapStyle.polygonStyle,
              strokeOpacity: 0.8,
              strokeWeight: 2,
              fillColor: mapStyle.polygonStyle,
              fillOpacity: 0.2
            }}
            onRightClick={(e) => onMapRightClick(e.latLng.lat(), e.latLng.lng())}
          />
        ))}
      </GoogleMap>
    </div>
  );
}

export default MapComponent;
